// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import * as Actions from './actions';
import * as ActionsType from './constants';
import { apiServiceUrl, LOGIN_PATH } from '../../constants';
import { fetchJSON, fetchBasicPostMethod, GetErrorMessageFromStatus, fetchPostMethodWithFormData, fetchJSONWithoutData } from '../../helpers/api';

/**
 * Sets the session
 * @param {*} user
 */
export const setSession = user => {
    let cookies = new Cookies();
    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
    try {
        const body = { login: username, password: password };
        const response = yield call(fetchBasicPostMethod, `${apiServiceUrl}/access/login`, username, password, body);
        if (response.status === 200) {
            if (response.body.status === 200) {
                setSession(response.body);
                yield put(Actions.loginUserSuccess(response.body));
            } else {
                throw { status: response.body.status };
            }

        } else {
            throw response;
        }

    } catch (error) {
        yield put(Actions.loginUserFailed(GetErrorMessageFromStatus(error)));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push(LOGIN_PATH);
        });
    } catch (error) { }
}

function* validateRegistrationEmail({ payload: { token } }) {
    const option = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json'
        }
    }
    const defaultErrorMessage = 'An unexpected error has occurred.';
    try {
        const response = yield call(fetchJSON, `${apiServiceUrl}/access/ValidateRegistrationEmail`, option);

        if (response.status === 200) {
            yield put(Actions.validateRegistrationEmailSuccess());
        } else {
            const { message } = yield response.data.then((response) => {
                return { message: response.message };
            }).catch(() => ({ message: defaultErrorMessage }));
            yield put(Actions.validateRegistrationEmailFailed(response.status, message));
        }
    } catch (error) {
        yield put(Actions.validateRegistrationEmailFailed(500, defaultErrorMessage));
    }
}

function* registerUserEmail({ payload: { email } }) {
    const options = {
        body: JSON.stringify({ email }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, `${apiServiceUrl}/access/RegisterEmail`, options);

        if (response.status === 200) {
            const { status, message } = yield response.data.then((response) => {
                return { status: response.status, message: response.description };
            }).catch(() => ({ status: 500 }));
            if (status === 200) {
                yield put(Actions.registerUserEmailSuccess());
            } else {
                const defaultMessage = GetErrorMessageFromStatus({ status });
                yield put(Actions.registerUserEmailFailed(message ?? defaultMessage));
            }
        } else {
            const { message } = yield response.data.then((response) => {
                return { message: response.description };
            }).catch(() => ({ status: 500 }));
            const defaultMessage = GetErrorMessageFromStatus(response);
            yield put(Actions.registerUserEmailFailed(message ?? defaultMessage));
        }
    } catch (error) {
        yield put(Actions.registerUserEmailFailed(GetErrorMessageFromStatus(error)));
    }
}

/**
 * Register the user
 */
function* register({ payload: {
    name, companyName, codeId, groupId, logo, country, state, city, zipcode, address, password, token
} }) {
    const data = new FormData();
    data.append('Name', name);
    data.append('CompanyName', companyName);
    data.append('GroupId', groupId);
    data.append('CodeId', codeId);
    data.append('Country', country);
    data.append('State', state);
    data.append('City', city);
    data.append('ZipCode', zipcode);
    data.append('Address', address);
    data.append('Password', password);
    data.append('Logo', logo);
    try {
        const response = yield call(
            fetchPostMethodWithFormData,
            `${apiServiceUrl}/access/Register`,
            token,
            data);

        if (response.status === 200) {
            yield put(Actions.registerUserSuccess());
        } else {
            const { message } = yield response.data.then((response) => {
                return { message: response.message };
            }).catch(() => ({ status: 500 }));
            const defaultMessage = GetErrorMessageFromStatus(response);
            yield put(Actions.registerUserFailed(message ?? defaultMessage));
        }
    } catch (error) {
        yield put(Actions.registerUserFailed(GetErrorMessageFromStatus(error)));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ Email: username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSONWithoutData, `${apiServiceUrl}/access/ForgotPassword`, options);
        if (response.status === 200) {
            yield put(Actions.forgetPasswordSuccess());
        } else {
            yield put(Actions.forgetPasswordFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.forgetPasswordFailed(GetErrorMessageFromStatus(error)));
    }
}

function* resetPassword({ payload: { username, password, code }}) {
    const options = {
        body: JSON.stringify({ Email: username, Code: code, Password: password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, `${apiServiceUrl}/access/ResetPassword`, options);
        if (response.status === 200) {
            const { body } = yield response.data
                .then((response) => ({ body: response }))
                .catch(() => ({ status: 500 }));
            if (body.status === 200) {
                setSession(body);
                yield put(Actions.loginUserSuccess(body));
            } else {
                throw { status: body.status };
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.loginUserFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            throw response;
        }

    } catch (error) {
        yield put(Actions.loginUserFailed(GetErrorMessageFromStatus(error)));
        setSession(null);
    }}

export function* watchLoginUser(): any {
    yield takeEvery(ActionsType.LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
    yield takeEvery(ActionsType.LOGOUT_USER, logout);
}

export function* watchRegisterUser(): any {
    yield takeEvery(ActionsType.REGISTER_USER, register);
}

export function* watchRegisterUserEmail(): any {
    yield takeEvery(ActionsType.REGISTER_USER_EMAIL, registerUserEmail);
}

export function* watchValidateRegistrationEmail(): any {
    yield takeEvery(ActionsType.VALIDATE_REGISTRATION_EMAIL, validateRegistrationEmail);
}

export function* watchForgetPassword(): any {
    yield takeEvery(ActionsType.FORGET_PASSWORD, forgetPassword);
}

export function* watchResetPassword(): any {
    yield takeEvery(ActionsType.RESET_PASSWORD, resetPassword);
}

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchRegisterUserEmail),
        fork(watchForgetPassword),
        fork(watchValidateRegistrationEmail),
        fork(watchResetPassword),
    ]);
}

export default authSaga;
